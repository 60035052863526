/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import {
    changeOrders,
    getOrders,
    switchOffSound,
} from '../../../redux/actions/dataActions'
import { echoOptions, pusherOptions } from '../../../util/broadcastOptions'
import OrderSkeleton from '../../../util/skeletons/OrderSkeleton'
import SoundEffect from '../../../components/SoundEffect'
import KitchenOrders from '../KitchenOrders'
import { connect } from 'react-redux'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
//MUI
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
    ordersWrap: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    orderTitle: {
        margin: '10px',
    },
    audio: {
        display: 'none',
    },
})

const KitchenStage = props => {
    const stage = useMemo(() => {
        return props.match.params.stage
    }, [props.match.params.stage])
    const { data, getOrders, changeOrders, switchOffSound, classes } = props
    let soundEffect = useMemo(() => {
        return data.sound && <SoundEffect stage={stage} />
    }, [data.sound])

    const { store_id } = localStorage
    
    const echo = useMemo(() => {
        return new Echo(echoOptions)
    }, [])
    
    const pusher = useMemo(() => {
        return new Pusher('654987', pusherOptions)
    }, [])

    useEffect(() => {
        let echoWay =
            stage === 'one'
                ? '.kitchen'
                : stage === 'two'
                ? '.one'
                : stage === 'three'
                ? '.two'
                : '.three'
        let echoChannel =
            stage === 'one'
                ? 'kitchen'
                : stage === 'two'
                ? 'station-one'
                : stage === 'three'
                ? 'station-two'
                : 'station-three'
        echo.channel(echoChannel + `_${store_id}`).listen(echoWay, e => {
            switchOffSound()
            changeOrders(e.products.original)
        })

        getOrders(stage)
        return () => {
            pusher.disconnect()
            echo.leave(echoChannel + `_${store_id}`)
            echo.leave('products')
            echo.leave('product')
            echo.leave('time')
        }
    }, [])

    return (
        <Grid>
            {soundEffect}
            <Grid className={classes.ordersWrap}>
                {data.loadingOrders ? (
                    <OrderSkeleton />
                ) : data.kitchenOrders.length > 0 ? (
                    <KitchenOrders orders={data.kitchenOrders} stage={stage} />
                ) : (
                    <p>Заказов пока нет</p>
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => ({
    data: state.data,
})
export default connect(mapStateToProps, {
    switchOffSound,
    getOrders,
    changeOrders,
})(withStyles(styles)(KitchenStage))
